import React from "react";
import { Link } from "react-router-dom";
import "../css/Modal.css";

class PasswordResetConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="modal fade" id="passwordResetConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitle">Password Reset Successful</h5>
                        </div>
                        <div className="modal-body">
                            <p className="modalcontent">
                                Your password was successfully reset. Please return to the login page and use your new password to log in.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <Link to="/login"><button data-toggle="modal" data-target="#passwordResetConfirmationModal" type="button" className="modal-button">Return to Login</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordResetConfirmationModal;
