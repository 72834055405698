import * as React from "react";
import { Edit, RadioButtonGroupInput, SimpleForm, TextInput } from "react-admin";
import * as globals from "../../globals";
import "../css/Page.css";

class TrainerEditPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Edit undoable={false} title="Edit Customer Information" className="d-flex justify-content-center" {...this.props}>
                <SimpleForm>
                    <TextInput disabled label="ID" source={globals.USER_ATTRIBUTES.TRAINER.TRAINER_ID} />
                    <TextInput disabled label="Email" source={globals.USER_ATTRIBUTES.TRAINER.EMAIL} />
                    <RadioButtonGroupInput label="Tier" source={globals.USER_ATTRIBUTES.TRAINER.TIER} justify-content="center" choices={[
                        { id: "1", name: "Group" },
                        { id: "2", name: "Personal" },
                        { id: "3", name: "Admin" }
                    ]} />
                </SimpleForm>
            </Edit>
        );
    }
}

export default TrainerEditPage;
