import * as React from "react";
import { Edit, RadioButtonGroupInput, SimpleForm, TextInput, SelectInput, SaveButton, Toolbar, regex } from "react-admin";
import * as globals from "../../globals";
import { isDodPoundUrl } from "../../client-config";
import "../css/Page.css";

class CustomerEditPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const validateUpdateUser = (values) => {
            const errors = {};
            if (values.height && !values.height.includes('_')) {
                errors.height = 'Must be in format "0_00"';
            }

            if (values.weight < 50 || values.weight > 650) {
                errors.weight = 'Must be between 50 and 650';
            }
            return errors
        };
        const birthdayValidator = regex(/^\d{4}-\d{2}-\d{2}$/, 'Must be in format "0000-00-00"')
        return (
            <div className="edit-form-container">
                <Edit undoable={false} title="Edit Customer Information" className="d-flex justify-content-between" {...this.props}>
                    <SimpleForm>
                        <TextInput disabled label="ID" source={globals.USER_ATTRIBUTES.CUSTOMER.CUSTOMER_ID} />
                        <TextInput disabled label="Email" source={globals.USER_ATTRIBUTES.CUSTOMER.EMAIL} />
                        {!isDodPoundUrl() && (
                            <RadioButtonGroupInput label="Tier" source={globals.USER_ATTRIBUTES.CUSTOMER.TIER} justify-content="center" choices={[
                                { id: "1", name: "One" },
                                { id: "2", name: "Two" },
                                { id: "3", name: "Three" }
                            ]} />
                        )}
                        <RadioButtonGroupInput label="Exempt" source={globals.USER_ATTRIBUTES.CUSTOMER.EXEMPT} justify-content="center" choices={[
                            { id: "true", name: "True" },
                            { id: "false", name: "False" }
                        ]} />
                    </SimpleForm>
                </Edit>
                <div className="profile__form">
                    <Edit undoable={false} title="." className="d-flex justify-content-between" {...this.props}>
                        <SimpleForm toolbar={<Toolbar {...this.props}><SaveButton /></Toolbar>} validate={validateUpdateUser}>
                            <TextInput label="First Name" source={globals.USER_CUSTOMER_ATTRIBUTES.NAME} />
                            <TextInput label="Family Name" source={globals.USER_CUSTOMER_ATTRIBUTES.FAMILY_NAME} />
                            <TextInput label="BirthDate" source={globals.USER_CUSTOMER_ATTRIBUTES.BIRTHDATE} validate={birthdayValidator}/>
                            <TextInput label="Height" source={globals.USER_CUSTOMER_ATTRIBUTES.HEIGHT} />
                            <TextInput label="Weight" source={globals.USER_CUSTOMER_ATTRIBUTES.WEIGHT} />
                            <SelectInput label="Gender" source={globals.USER_CUSTOMER_ATTRIBUTES.GENDER} choices={[
                                { id: 'M', name: 'Male' },
                                { id: 'F', name: 'Female' },
                                { id: 'NB', name: 'Non-binary' },
                            ]} />
                            {isDodPoundUrl() && (
                              <TextInput label="Organization" source={globals.USER_CUSTOMER_ATTRIBUTES.ORGANIZATION} />
                            )}
                        </SimpleForm>
                    </Edit>
                </div>
            </div>
        );
    }
}

export default CustomerEditPage;
