const API_RESPONSE_TYPES = {
    CONFIRMATION_RESPONSE: "ConfirmationResponse",
    CUSTOMER_SECURE: "CustomerSecure",
    LOGIN_RESPONSE: "LoginResponse",
    TRAINER_SECURE: "TrainerSecure",
    CUSTOMER_GET: "customerGet",
};

const APP_BUIDS = {
    LOCAL: "local",
    PRODUCTION: "prod",
    STAGE: "stage"
};

const AUTHENTICATION_CONTEXTS = {
    PASSWORD_RESET: "PASSWORD_RESET",
    SIGNUP: "SIGNUP"
};

const LOGIN_TOKENS = {
    ACCESS_TOKEN: "access_token",
    ID_TOKEN: "id_token",
    REFRESH_TOKEN: "refresh_token"
};

const SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT = `
    ... on ${API_RESPONSE_TYPES.CONFIRMATION_RESPONSE} {
        success,
        errors,
        data
    }
`;

const SORTING_ORDER = {
    ASC: "ASC",
    DESC: "DESC"
};

const USER_CUSTOMER_ATTRIBUTES = {
    ORGANIZATION: "organization",
    WEIGHT: "weight",
    HEIGHT: 'height',
    BIRTHDATE: 'birthdate',
    GENDER: "gender",
    NAME: "name",
    FAMILY_NAME: "family_name",
};

const USER_ATTRIBUTES = {
    CUSTOMER: {
        CUSTOMER_ID: "customer_id",
        EMAIL: "email",
        EXEMPT: "exempt",
        TIER: "tier",
        FAMILY_NAME: "family_name",
    },
    TRAINER: {
        TRAINER_ID: "trainer_id",
        EMAIL: "email",
        TIER: "tier"
    }
};

const USER_TYPES = {
    CUSTOMER: "customer",
    TRAINER: "trainer"
};

export {
    API_RESPONSE_TYPES,
    APP_BUIDS,
    AUTHENTICATION_CONTEXTS,
    LOGIN_TOKENS,
    SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT,
    SORTING_ORDER,
    USER_ATTRIBUTES,
    USER_TYPES,
    USER_CUSTOMER_ATTRIBUTES
};
