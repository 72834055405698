import * as axios from "axios";
import { getSecretKey } from "./client-config";

function createSequentialPromiseChain(functions) {
    return functions.reduce((promise, func) =>
        promise.then(result =>
            func().then(Array.prototype.concat.bind(result))),
        Promise.resolve([]));
}

function issueRequest(endPointParams, queryFields) {
    const url = process.env.REACT_APP_ENDPOINT || "http://localhost:5001/";

    const requestHeaders = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "H-Platform-Secret-Key": getSecretKey()
        }
    }

    const queryFieldsList = queryFields || [];

    return new Promise((resolve, reject) => {
        axios.post(url,
            { query: endPointParams.query(...queryFieldsList) },
            requestHeaders)
            .then(response => {
                const responseData = response.data.data;
                const details = responseData[endPointParams.responseField];
                resolve(details);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
    })
}

function issueMutation(endPointParams, queryFields) {
    return issueRequest(endPointParams, queryFields)
        .then(mutationResponse => {
            if (mutationResponse.success === true) {
                return Promise.resolve(mutationResponse.data);
            }

            return Promise.reject(mutationResponse.errors)
        });
}

export {
    createSequentialPromiseChain,
    issueRequest,
    issueMutation
};
