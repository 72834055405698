// Documentation: https://marmelab.com/react-admin/DataProviders.html
import moment from "moment";
import * as endpoints from "./endpoints";
import * as globals from "./globals";
import * as utils from "./utils";

const CUSTOMER_FRAGMENT = `
    ... on ${globals.API_RESPONSE_TYPES.CUSTOMER_SECURE} {
        ${globals.USER_ATTRIBUTES.CUSTOMER.CUSTOMER_ID},
        ${globals.USER_ATTRIBUTES.CUSTOMER.EMAIL},
        ${globals.USER_ATTRIBUTES.CUSTOMER.EXEMPT},
        ${globals.USER_ATTRIBUTES.CUSTOMER.TIER}
    }
`;

const TRAINER_FRAGMENT = `
... on ${globals.API_RESPONSE_TYPES.TRAINER_SECURE} {
        ${globals.USER_ATTRIBUTES.TRAINER.TRAINER_ID},
        ${globals.USER_ATTRIBUTES.TRAINER.EMAIL},
        ${globals.USER_ATTRIBUTES.TRAINER.TIER}
    }
`;

const USER_GET_FRAGMENT = `
    ${globals.USER_CUSTOMER_ATTRIBUTES.BIRTHDATE},
    ${globals.USER_CUSTOMER_ATTRIBUTES.FAMILY_NAME},
    ${globals.USER_CUSTOMER_ATTRIBUTES.NAME},
    ${globals.USER_CUSTOMER_ATTRIBUTES.GENDER},
    ${globals.USER_CUSTOMER_ATTRIBUTES.HEIGHT},
    ${globals.USER_CUSTOMER_ATTRIBUTES.WEIGHT},
    ${globals.USER_CUSTOMER_ATTRIBUTES.ORGANIZATION},
`;

// BEGIN HELPER FUNCTIONS

function sortList(userList, sort) {
    const sortField = sort ? sort.field : null;
    const sortOrder = sort ? sort.order : null;

    switch (sortOrder) {
        case globals.SORTING_ORDER.ASC:
            return userList.sort((userA, userB) => (userA[sortField] > userB[sortField]) ? 1 : (userA[sortField] < userB[sortField] ? -1 : 0));
        case globals.SORTING_ORDER.DESC:
            return userList.sort((userA, userB) => (userA[sortField] < userB[sortField]) ? 1 : (userA[sortField] > userB[sortField] ? -1 : 0));
        default:
            return userList;
    }
}

function paginateList(userList, pagination) {
    const paginationPage = pagination ? pagination.page : null;
    const paginationPerPage = pagination ? pagination.perPage : null;

    return pagination ? userList.slice((paginationPage - 1) * paginationPerPage, paginationPage * paginationPerPage) : userList;
}

// END HELPER FUNCTIONS

const createResolvers = {

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        const customerCreationInfo = params.data;
        const firstName = customerCreationInfo.firstName;
        const lastName = customerCreationInfo.lastName;
        const birthdate = moment(customerCreationInfo.birthdate).format("YYYY-MM-DD");
        const gender = customerCreationInfo.gender;
        const height = `${customerCreationInfo.ft}_${customerCreationInfo.in}`;
        const weight = customerCreationInfo.weight;
        const email = customerCreationInfo.email;
        const organization = customerCreationInfo.organization;
        const password = customerCreationInfo.password;

        const queryArgs = [
            firstName,
            lastName,
            gender,
            birthdate,
            height,
            weight,
            email,
            password,
            organization
        ];

        return utils.issueMutation(endpoints.customerEndpoints.customerCreateEndpointParams, queryArgs)
            .then(responseData => {
                const customerId = responseData;
                const fetchQueryArgs = [customerId, CUSTOMER_FRAGMENT];
                return utils.issueRequest(endpoints.customerEndpoints.customerGetSecureEndpointParams, fetchQueryArgs);
            })
            .then(customerData => {
                const customerId = customerData[globals.USER_ATTRIBUTES.CUSTOMER.CUSTOMER_ID];
                const finalRecord = { ...customerData, id: customerId };
                return Promise.resolve({ data: finalRecord })
            });
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const trainerCreationInfo = params.data;

        if (Object.keys(trainerCreationInfo).length !== 6) { // Ignore confirm password
            throw "Error: please fill out all the fields";
        }

        const firstName = trainerCreationInfo.firstName;
        const lastName = trainerCreationInfo.lastName;
        const tier = trainerCreationInfo.tier;
        const email = trainerCreationInfo.email;
        const password = trainerCreationInfo.password;

        const queryArgs = [
            firstName,
            lastName,
            email,
            password,
            tier
        ];

        return utils.issueMutation(endpoints.trainerEndpoints.trainerCreateEndpointParams, queryArgs)
            .then(responseData => {
                const trainerId = responseData;
                const fetchQueryArgs = [trainerId, TRAINER_FRAGMENT];
                return utils.issueRequest(endpoints.trainerEndpoints.trainerGetSecureEndpointParams, fetchQueryArgs);
            })
            .then(trainerData => {
                const trainerId = trainerData[globals.USER_ATTRIBUTES.TRAINER.TRAINER_ID];
                const finalRecord = { ...trainerData, id: trainerId };
                return Promise.resolve({ data: finalRecord })
            });
    }

}

const deleteResolvers = {

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        const customerId = params.id;
        const existingCustomerData = params.previousData;

        const queryArgs = [customerId];

        return utils.issueMutation(endpoints.customerEndpoints.customerWipeEndpointParams, queryArgs)
            .then(_ => Promise.resolve({ data: existingCustomerData }));
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const trainerId = params.id;
        const existingTrainerData = params.previousData;

        const queryArgs = [trainerId];

        return utils.issueMutation(endpoints.trainerEndpoints.trainerWipeEndpointParams, queryArgs)
            .then(_ => Promise.resolve({ data: existingTrainerData }));
    }

}

const deleteManyResolvers = {

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        const customerIds = params.ids;

        const wipePromiseChain = utils.createSequentialPromiseChain(customerIds.map(customerId => {
            const queryArgs = [customerId];
            return function () { return utils.issueMutation(endpoints.customerEndpoints.customerWipeEndpointParams, queryArgs); };
        }));

        return wipePromiseChain.then(_ => Promise.resolve({ data: customerIds }));
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const trainerIds = params.ids;

        const wipePromiseChain = utils.createSequentialPromiseChain(trainerIds.map(trainerId => {
            const queryArgs = [trainerId];
            return function () { return utils.issueMutation(endpoints.trainerEndpoints.trainerWipeEndpointParams, queryArgs); };
        }));

        return wipePromiseChain.then(_ => Promise.resolve({ data: trainerIds }));
    }

}

const getListResolvers = {

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        if (window.location.hash.length > 11 && !window.location.hash.includes('?')) {
            const r = {data: [], total: 0};
            return Promise.resolve(r);
        }
        const pagination = params.pagination;
        const sort = params.sort;
        const filter = params.filter;

        const queryArgs = [CUSTOMER_FRAGMENT];

        return utils.issueRequest(endpoints.customerEndpoints.customerGetAllEndpointParams, queryArgs)
            .then(allCustomers => {

                var modifiedCustomerData = allCustomers.map(customer => { return { ...customer, id: customer[globals.USER_ATTRIBUTES.CUSTOMER.CUSTOMER_ID] } });

                //CUSTOMER FILTERING PHASE
                modifiedCustomerData = Object.keys(filter).length != 0 ? modifiedCustomerData.filter(user => user.email.startsWith(filter.email)) : modifiedCustomerData;

                //CUSTOMER SORTING PHASE
                modifiedCustomerData = sortList(modifiedCustomerData, sort);

                const totalCustomers = modifiedCustomerData.length;

                //CUSTOMER PAGINATION PHASE
                modifiedCustomerData = paginateList(modifiedCustomerData, pagination);

                return Promise.resolve({ data: modifiedCustomerData, total: totalCustomers })
            });
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const pagination = params.pagination;
        const sort = params.sort;
        const filter = params.filter;

        const queryArgs = [TRAINER_FRAGMENT];

        return utils.issueRequest(endpoints.trainerEndpoints.trainerGetAllEndpointParams, queryArgs)
            .then(allTrainers => {
                var modifiedTrainerData = allTrainers.map(trainer => { return { ...trainer, id: trainer[globals.USER_ATTRIBUTES.TRAINER.TRAINER_ID] } });

                // TRAINER FITLERING PHASE
                modifiedTrainerData = Object.keys(filter).length != 0 ? modifiedTrainerData.filter(user => user.email.startsWith(filter.email)) : modifiedTrainerData;

                // TRAINER SORTING PHASE
                modifiedTrainerData = sortList(modifiedTrainerData, sort);

                const totalTrainers = modifiedTrainerData.length;

                // TRAINER PAGINATION PHASE
                modifiedTrainerData = paginateList(modifiedTrainerData, pagination);

                return Promise.resolve({ data: modifiedTrainerData, total: totalTrainers })
            });
    }

}

const getOneResolvers = { // Do not need to fetch since children of selected element get passed

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        const customerId = params.id;

        const queryArgs = [customerId, CUSTOMER_FRAGMENT];
        const queryArgs2 = [customerId, USER_GET_FRAGMENT];
        const p = utils.issueRequest(endpoints.customerEndpoints.customerGetSecureEndpointParams, queryArgs)
            .then(customerData => {
                const finalRecord = { ...customerData, id: customerId };
                return Promise.resolve({ data: finalRecord })
            });

        const p1 = utils.issueRequest(endpoints.customerEndpoints.customerGet, queryArgs2)
            .then(customerData => {
                const finalRecord = { ...customerData, id: customerId };
                return Promise.resolve({ data: finalRecord })
            });
        return Promise.all([p, p1]).then(result => {
            let r = {};
            result.forEach((e) => {
                r = {...r, ...e.data}
            })
            const finalRecord = { ...r, id: customerId };
            return Promise.resolve({ data: finalRecord })
        });
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const trainerId = params.id;

        const queryArgs = [trainerId, TRAINER_FRAGMENT];

        return utils.issueRequest(endpoints.trainerEndpoints.trainerGetSecureEndpointParams, queryArgs)
            .then(trainerData => {
                const finalRecord = { ...trainerData, id: trainerId };
                return Promise.resolve({ data: finalRecord })
            });
    },

}

const updateResolvers = {

    [globals.USER_TYPES.CUSTOMER]: (params) => {
        const editExistingForm = {};
        const newParams = {};
        const existingKeys = ['customer_id', 'email', 'exempt', 'id', 'tier'];
        const newKeys = ['name', 'family_name', 'customer_id', 'gender', 'birthdate', 'height', 'weight', 'organization'];
        Object.keys(params[0].data).forEach(p => {
            const key = existingKeys.includes(p);
            const newKey = newKeys.includes(p);
            if (newKey) {
                newParams[p] = params[0].data[p];
            }
            if (key) {
                editExistingForm[p] = params[0].data[p];
            }
        });
        const customerId = editExistingForm.id;
        const customerExemptionUpdate = editExistingForm[globals.USER_ATTRIBUTES.CUSTOMER.EXEMPT];
        const customerTier = editExistingForm[globals.USER_ATTRIBUTES.CUSTOMER.TIER];

        const queryArgs = [customerId, customerExemptionUpdate, customerTier];
        const queryArgs2 = [customerId, newParams];
        const p = utils.issueRequest(endpoints.customerEndpoints.customerUpdateExemptionEndpointParams, queryArgs)
            .then(_ => Promise.resolve({ data: { id: customerId } }));
        const p1 = utils.issueRequest(endpoints.customerEndpoints.customerUpdateStandardInformation, queryArgs2)
            .then(_ => Promise.resolve({ data: { id: customerId } }));
        return Promise.all([p, p1]).then(result => {
            let r = {};
            result.forEach((e) => {
                r = {...r, ...e.data}
            })
            return Promise.resolve({ data: {id: customerId} })
        });
    },

    [globals.USER_TYPES.TRAINER]: (params) => {
        const trainerId = params.id;
        const trainerTierUpdate = params.data[globals.USER_ATTRIBUTES.TRAINER.TIER];

        const queryArgs = [trainerId, trainerTierUpdate];

        return utils.issueRequest(endpoints.trainerEndpoints.trainerUpdateTierEndpointParams, queryArgs)
            .then(_ => Promise.resolve({ data: { id: trainerId } }));
    }

}

const dataProvider = {
    getList: (resource, params) => getListResolvers[resource](params),
    getOne: (resource, params) => getOneResolvers[resource](params),
    getSingleCustomer: (resource, params) => getOneResolvers[resource](params),
    getMany: (resource, params) => Promise.resolve(),
    getManyReference: (resource, params) => Promise.resolve(),
    create: (resource, params) => createResolvers[resource](params),
    update: (resource, ...params) => updateResolvers[resource](params),
    updateMany: (resource, params) => Promise.resolve(),
    delete: (resource, params) => deleteResolvers[resource](params),
    deleteMany: (resource, params) => deleteManyResolvers[resource](params)
}

export default dataProvider;
