import React from "react";
import { BulkDeleteButton, Datagrid, EditButton, EmailField, List, TextField } from "react-admin";
import * as globals from "../../globals";
import SearchFilterWidget from "../widgets/SearchFilterWidget";

class TrainerListEntry extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <List {...this.props} bulkActionButtons={<BulkDeleteButton {...this.props} undoable={false} />} filters={<SearchFilterWidget />}>
                <Datagrid>
                    <TextField label="ID" source={globals.USER_ATTRIBUTES.TRAINER.TRAINER_ID} />
                    <EmailField label="Email" source={globals.USER_ATTRIBUTES.TRAINER.EMAIL} />
                    <TextField label="Tier" source={globals.USER_ATTRIBUTES.TRAINER.TIER} />
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default TrainerListEntry;
