import * as React from "react";
import { Create, RadioButtonGroupInput, SimpleForm, PasswordInput, TextInput } from "react-admin";
import * as endpoints from "../../endpoints.js";
import * as globals from "../../globals";
import * as utils from "../../utils";
import "../css/Page.css";

class TrainerSignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordValue: "",
            confirmPasswordValue: "",
            errors: {}
        };
        this.authenticationContext = globals.AUTHENTICATION_CONTEXTS.SIGNUP;
        this.formatErrors = this.formatErrors.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    formatErrors(errors) {
        const errorComponents = errors.map(error => <u className="text-danger"><font size="2">{error}</font></u>);
        return errorComponents;
    }

    handleOnChange(event) {
        event.preventDefault();

        const signupObj = this;
        const field = event.target.id;
        const value = event.target.value;

        if (field === "password") {
            if (this.state.confirmPasswordValue !== value) {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                signupObj.setState({ passwordValue: value, errors: newErrors });
            }
            else {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: [] };
                signupObj.setState({ passwordValue: value, errors: newErrors });
            }
        }
        else if (field === "confirmPassword") {
            if (this.state.passwordValue !== value) {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                signupObj.setState({ confirmPasswordValue: value, errors: newErrors });
            }
            else {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: [] };
                signupObj.setState({ confirmPasswordValue: value, errors: newErrors });
            }
            return; // Do not call server for confirm password
        }

        const queryFields = [field, globals.AUTHENTICATION_CONTEXTS.SIGNUP, value];

        utils.issueMutation(endpoints.trainerEndpoints.trainerFieldPrecheckEndpointParams, queryFields)
            .then(_ => {
                const newErrors = { ...signupObj.state.errors, [field]: [] };
                signupObj.setState({ errors: newErrors });
            })
            .catch(errors => {
                const newErrors = { ...signupObj.state.errors, [field]: errors };
                signupObj.setState({ errors: newErrors });
            })
    }

    render() {
        const emailErrors = this.state.errors["email"];
        const passwordErrors = this.state.errors["password"];
        const confirmPasswordErrors = this.state.errors["confirmPassword"];

        return (
            <Create title="Trainer Registration" className="d-flex justify-content-center" {...this.props}>
                <SimpleForm>
                    <TextInput label="First Name" source="firstName" />
                    <TextInput label="Last Name" source="lastName" />
                    <TextInput label="Email" source={globals.USER_ATTRIBUTES.TRAINER.EMAIL} onChange={this.handleOnChange} />
                    {
                        emailErrors && this.formatErrors(emailErrors)
                    }
                    <PasswordInput label="Password" source="password" onChange={this.handleOnChange} />
                    {
                        passwordErrors && this.formatErrors(passwordErrors)
                    }
                    <PasswordInput label="Confirm Password" source="confirmPassword" onChange={this.handleOnChange} />
                    {
                        confirmPasswordErrors && this.formatErrors(confirmPasswordErrors)
                    }
                    <RadioButtonGroupInput label="Tier" source={globals.USER_ATTRIBUTES.TRAINER.TIER} justify-content="center" choices={[
                        { id: "1", name: "Group" },
                        { id: "2", name: "Personal" },
                        { id: "3", name: "Admin" }
                    ]} />
                </SimpleForm>
            </Create>
        );
    }
}

export default TrainerSignupPage;
