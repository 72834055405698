import React from "react";
import { Redirect } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import dataProvider from "../../dataProvider";
import * as globals from "../../globals";
import CustomerEditPage from "./CustomerEditPage";
import CustomerListEntry from "../entries/CustomerListEntry";
import CustomerSignupPage from "./CustomerSignupPage";
import TrainerEditPage from "./TrainerEditPage";
import TrainerListEntry from "../entries/TrainerListEntry";
import TrainerSignupPage from "./TrainerSignupPage";
import UserIcon from "@material-ui/icons/People";
import { isDodPoundUrl } from "../../client-config";
import "../css/Page.css";

class MainDashboardPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const loggedIn = sessionStorage.getItem("isLoggedIn");

        if (loggedIn) {
            const authProvider = {
                logout: () => {
                    sessionStorage.clear();
                    localStorage.clear();
                    return Promise.resolve();
                },
                checkAuth: () => loggedIn ? Promise.resolve() : Promise.reject(),
                checkError: (error) => Promise.resolve(),
                getPermissions: () => loggedIn ? Promise.resolve(true) : Promise.reject()
            }

            return (
                <Admin authProvider={authProvider} dataProvider={dataProvider}>
                    {
                        window.build == globals.APP_BUIDS.PRODUCTION && !isDodPoundUrl()
                            ? <Resource name={globals.USER_TYPES.CUSTOMER} edit={CustomerEditPage} list={CustomerListEntry} icon={UserIcon} />
                            : <Resource name={globals.USER_TYPES.CUSTOMER} create={CustomerSignupPage} edit={CustomerEditPage} list={CustomerListEntry} icon={UserIcon} />
                    }
                    <Resource name={globals.USER_TYPES.TRAINER} create={TrainerSignupPage} edit={TrainerEditPage} list={TrainerListEntry} icon={UserIcon} />
                </Admin>
            );
        }
        else {
            return <Redirect to="/login" />;
        }
    }
}


export default MainDashboardPage;
