import React from "react";
import { Link } from "react-router-dom";
import * as endpoints from "../../endpoints";
import * as utils from "../../utils";
import PasswordResetConfirmationModal from "../modals/PasswordResetConfirmationModal";
import "../css/Page.css";

class PasswordResetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            resetCode: "",
            password: "",
            confirmPassword: "",
            emailSuccess: false,
            resetSuccess: false,
            errors: {}
        };
        this.formatErrors = this.formatErrors.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
        this.handleSubmitCodeReceived = this.handleSubmitCodeReceived.bind(this);
    }

    formatErrors(errors) {
        const errorComponents = errors.map(error => <h3 className="text-light"><font size="2">{error}</font></h3>);
        return errorComponents;
    }

    handleChange(event) {
        event.preventDefault();

        const resetObj = this;
        const field = event.target.id;
        const value = event.target.value;

        const fieldStateMap = {
            email: "email",
            password: "password",
            confirm_password: "confirmPassword",
            reset_code: "resetCode"
        };

        const impactedState = fieldStateMap[field];

        if (impactedState === "password") {
            if (this.state.confirmPassword !== value) {
                const newErrors = { ...resetObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                resetObj.setState({ errors: newErrors });
            }
            else {
                const newErrors = { ...resetObj.state.errors, ["confirmPassword"]: [] };
                resetObj.setState({ errors: newErrors });
            }
        }
        else if (impactedState === "confirmPassword") {
            if (this.state.password !== value) {
                const newErrors = { ...resetObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                resetObj.setState({ confirmPassword: value, errors: newErrors });
            }
            else {
                const newErrors = { ...resetObj.state.errors, ["confirmPassword"]: [] };
                resetObj.setState({ confirmPassword: value, errors: newErrors });
            }
            return; // Do not call server for confirm password
        }

        const queryArgs = [field, value];

        utils.issueMutation(endpoints.adminEndpoints.adminFieldPrecheckEndpointParams, queryArgs)
            .then(_ => {
                const newErrors = { ...resetObj.state.errors, [impactedState]: [] };
                resetObj.setState({ [impactedState]: value, errors: newErrors });
            })
            .catch(errors => {
                const newErrors = { ...resetObj.state.errors, [impactedState]: errors };
                resetObj.setState({ [impactedState]: value, errors: newErrors });
            });
    }

    handleSubmitEmail(event) {
        event.preventDefault();
        const resetObj = this;

        const email = this.state.email;
        const queryFields = [email];

        utils.issueMutation(endpoints.adminEndpoints.adminPasswordResetStartEndpointParams, queryFields)
            .then(_ => {
                resetObj.setState({ errors: {}, emailSuccess: true });
            })
            .catch(err => {
                resetObj.setState({ errors: { email: err } });
            });
    }

    handleSubmitCodeReceived(event) {
        event.preventDefault();
        const resetObj = this;

        const email = this.state.email;
        const resetCode = this.state.resetCode;
        const password = this.state.password;

        const queryFields = [email, resetCode, password];
        utils.issueMutation(endpoints.adminEndpoints.adminPasswordResetCompleteEndpointParams, queryFields)
            .then(_ => {
                resetObj.setState({ errors: {}, resetSuccess: true });
                document.getElementById("passwordResetConfirmationModalButton").click();
            });
    }

    render() {
        const emailErrors = this.state.errors["email"];
        const resetCodeErrors = this.state.errors["resetCode"];
        const passwordErrors = this.state.errors["password"];
        const confirmPasswordErrors = this.state.errors["confirmPassword"];

        const disablePasswordReset = !this.state.email || !this.state.password || !this.state.resetCode || !this.state.confirmPassword
            || (!!emailErrors && emailErrors.length > 0) || (!!resetCodeErrors && resetCodeErrors.length > 0) || (!!passwordErrors && passwordErrors.length > 0)
            || (!!confirmPasswordErrors && confirmPasswordErrors.length > 0);

        return (
            <div className="password-reset-page-wrapper">
                <div className="pt-3 pb-2">
                    <div className="auth-page-header">Presence Administrator Password Reset</div>
                    <p className="text-light">Please enter the email address associated with your account.<Link to="/login" className="pl-1"><u>Back to Login</u></Link></p>
                </div>
                {
                    this.state.emailSuccess === false && (
                        <form onSubmit={this.handleSubmitEmail} className="auth-form">
                            <div className="modal-body d-flex">
                                <label htmlFor="email" className="text-light col-md-3">Email</label>
                                <div className="col-md-9">
                                    <input type="text" className="form-control" id="email" placeholder="Enter email" onChange={this.handleChange} />
                                </div>
                            </div>
                            {
                                emailErrors && this.formatErrors(emailErrors)
                            }
                            <button type="submit" className="main-button auth-button" disabled={!this.state.email || emailErrors.length > 0}>Submit</button>
                        </form>
                    )
                }
                {
                    this.state.emailSuccess && (
                        <p className={"text-light"}>
                            Password reset link sent to {this.state.email}.
                        </p>
                    )
                }
                {
                    this.state.emailSuccess && (
                        <form onSubmit={this.handleSubmitCodeReceived} className="auth-form">
                            <div className="modal-body d-flex pt-4">
                                <label htmlFor="reset_code" className="text-light col-md-3">Reset Code</label>
                                <div className="col-md-9">
                                    <input type="text" className="form-control" id="reset_code" placeholder="Email Confirmation Code" onChange={this.handleChange} />
                                </div>
                            </div>
                            {
                                resetCodeErrors && this.formatErrors(resetCodeErrors)
                            }
                            <div className="modal-body d-flex">
                                <label htmlFor="password" className="text-light col-md-3">New Password</label>
                                <div className="col-md-9">
                                    <input type="password" className="form-control" id="password" placeholder="New Password" onChange={this.handleChange} />
                                </div>
                            </div>
                            {
                                passwordErrors && this.formatErrors(passwordErrors)
                            }
                            <div className="modal-body d-flex">
                                <label htmlFor="confirm_password" className="text-light col-md-3">Confirm Password</label>
                                <div className="col-md-9">
                                    <input type="password" className="form-control" id="confirm_password" placeholder="Confirm New Password" onChange={this.handleChange} />
                                </div>
                            </div>
                            {
                                confirmPasswordErrors && this.formatErrors(confirmPasswordErrors)
                            }
                            <div className="pt-3">
                                <button type="submit" className="main-button auth-button" disabled={disablePasswordReset}>Reset Password</button>
                            </div>
                        </form>
                    )
                }
                <button className="d-none" data-toggle="modal" data-target="#passwordResetConfirmationModal" data-backdrop="static" data-keyboard="false" id="passwordResetConfirmationModalButton" />
                <PasswordResetConfirmationModal />
            </div>
        );
    }
}

export default PasswordResetPage;
