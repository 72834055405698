import React from "react";
import { Filter, TextInput } from "react-admin";

class SearchFilterWidget extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Filter {...this.props}>
                <TextInput label="Search by email" source="email" alwaysOn />
            </Filter>
        );
    }
}

export default SearchFilterWidget;
