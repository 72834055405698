import * as React from "react";
import { Create, RadioButtonGroupInput, SimpleForm, NumberInput, PasswordInput, TextInput, DateInput } from "react-admin";
import * as endpoints from "../../endpoints.js";
import * as globals from "../../globals";
import * as utils from "../../utils";
import "../css/Page.css";
import { isDodPoundUrl } from "../../client-config";

class CustomerSignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordValue: "",
            confirmPasswordValue: "",
            errors: {}
        };
        this.formatErrors = this.formatErrors.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    formatErrors(errors) {
        const errorComponents = errors.map(error => <u className="text-danger"><font size="2">{error}</font></u>);
        return errorComponents;
    }

    handleOnChange(event) {
        event.preventDefault();

        const signupObj = this;
        const field = event.target.id;
        const value = event.target.value;

        if (field === "password") {
            if (this.state.confirmPasswordValue !== value) {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                signupObj.setState({ passwordValue: value, errors: newErrors });
            }
            else {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: [] };
                signupObj.setState({ passwordValue: value, errors: newErrors });
            }
        }
        else if (field === "confirmPassword") {
            if (this.state.passwordValue !== value) {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: ["ERROR: Passwords do not match"] };
                signupObj.setState({ confirmPasswordValue: value, errors: newErrors });
            }
            else {
                const newErrors = { ...signupObj.state.errors, ["confirmPassword"]: [] };
                signupObj.setState({ confirmPasswordValue: value, errors: newErrors });
            }
            return; // Do not call server for confirm password
        }

        const queryArgs = [field, globals.AUTHENTICATION_CONTEXTS.SIGNUP, value];

        utils.issueMutation(endpoints.customerEndpoints.customerFieldPrecheckEndpointParams, queryArgs)
            .then(_ => {
                const newErrors = { ...signupObj.state.errors, [field]: [] };
                signupObj.setState({ errors: newErrors });
            })
            .catch(errors => {
                const newErrors = { ...signupObj.state.errors, [field]: errors };
                signupObj.setState({ errors: newErrors });
            });
    }

    render() {
        const emailErrors = this.state.errors["email"];
        const passwordErrors = this.state.errors["password"];
        const confirmPasswordErrors = this.state.errors["confirmPassword"];

        return (
            <Create title="Customer Sign Up" className="d-flex justify-content-center" {...this.props}>
                <SimpleForm>
                    <TextInput label="First Name" source="firstName" />
                    <TextInput label="Last name" source="lastName" />
                    <DateInput label="Birthdate" source="birthdate" defaultValue={new Date()} />
                    <RadioButtonGroupInput label="Gender" source="gender" justify-content="center" choices={[
                        { id: "M", name: "Male" },
                        { id: "F", name: "Female" },
                        { id: "NB", name: "Non-Binary" }
                    ]} />
                    <div className="d-flex">
                        <div className="col-md-6">
                            <NumberInput label="Ht. (ft)" source="ft" justify-content="center" />
                        </div>
                        <div className="col-md-6">
                            <NumberInput label="Ht. (in)" source="in" justify-content="center" />
                        </div>
                    </div>
                    <NumberInput label="Weight (lbs)" source="weight" justify-content="center" />
                    <TextInput label="Email" source={globals.USER_ATTRIBUTES.CUSTOMER.EMAIL} onChange={this.handleOnChange} />
                    {
                        emailErrors && this.formatErrors(emailErrors)
                    }
                    {isDodPoundUrl() && (
                      <TextInput label="Organization" source={globals.USER_CUSTOMER_ATTRIBUTES.ORGANIZATION} />
                    )}
                    <PasswordInput label="Password" source="password" onChange={this.handleOnChange} />
                    {
                        passwordErrors && this.formatErrors(passwordErrors)
                    }
                    <PasswordInput label="Confirm Password" source="confirmPassword" onChange={this.handleOnChange} />
                    {
                        confirmPasswordErrors && this.formatErrors(confirmPasswordErrors)
                    }
                </SimpleForm>
            </Create>
        );
    }
}

export default CustomerSignupPage;
