import * as globals from "./globals";

function createQuery(request, fragment = null, parameters = null) {
    const fragmentString = (fragment) ? `{ ${fragment} }` : "";

    const parameterList = (parameters) ? Object.keys(parameters).map(key => {
        return `${key}: "${parameters[key]}"`;
    }) : [];

    const parameterString = (parameters) ? `(${parameterList.join()})` : "";

    return `query { ${request}${parameterString}${fragmentString} }`
}

function createMutation(request, parameters) {
    const parameterList = (parameters) ? Object.keys(parameters).map(key => {
        return `${key}: "${parameters[key]}"`;
    }) : [];

    const parameterString = (parameters) ? `(${parameterList.join()})` : "";

    return `mutation { ${request}${parameterString}{ ${globals.SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT} } }`;
}

const adminEndpoints = {

    adminFieldPrecheckEndpointParams: {
        query: (field, value) => {
            var parameters = {
                field: field,
                value: value
            };

            return createQuery("adminFieldPrecheck", globals.SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT, parameters);
        },
        responseField: "adminFieldPrecheck"
    },

    adminLoginEndpointParams: {
        query: (email, password, fragment) => {
            const parameters = {
                email: email,
                password: password
            };

            return createQuery("adminLogin", fragment, parameters);
        },
        responseField: "adminLogin"
    },

    adminLoginRefreshTokenEndpointParams: {
        query: (refreshToken, fragment) => {
            const parameters = {
                refresh_token: refreshToken
            };

            return createQuery("adminLoginRefreshToken", fragment, parameters);
        },
        responseField: "adminLoginRefreshToken"
    },

    adminPasswordResetStartEndpointParams: {
        query: (email) => {
            const parameters = {
                email: email
            };

            return createMutation("adminPasswordResetStart", parameters);
        },
        responseField: "adminPasswordResetStart"
    },

    adminPasswordResetCompleteEndpointParams: {
        query: (email, confirmationCode, password) => {
            const parameters = {
                email: email,
                confirmation_code: confirmationCode,
                password: password
            };

            return createMutation("adminPasswordResetComplete", parameters);
        },
        responseField: "adminPasswordResetComplete"
    }

}

const customerEndpoints = {

    customerCreateEndpointParams: {
        query: (firstName, lastName, gender, birthdate, height, weight, email, password, organization) => {
            const parameters = {
                first_name: firstName,
                last_name: lastName,
                gender: gender,
                birthdate: birthdate,
                height: height,
                weight: weight,
                email: email,
                password: password,
                organization: organization
            };

            return createMutation("customerCreate", parameters);
        },
        responseField: "customerCreate"
    },

    customerFieldPrecheckEndpointParams: {
        query: (field, context, value) => {
            const parameters = {
                field: field,
                context: context,
                value: value
            };

            return createQuery("customerFieldPrecheck", globals.SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT, parameters);
        },
        responseField: "customerFieldPrecheck"
    },

    customerGetAllEndpointParams: {
        query: (fragment) => {
            return createQuery("customerGetAll", fragment);
        },
        responseField: "customerGetAll"
    },

    customerGetSecureEndpointParams: {
        query: (customerId, fragment) => {
            const parameters = {
                customer_id: customerId
            };

            return createQuery("customerGetSecure", fragment, parameters);
        },
        responseField: "customerGetSecure"
    },
    customerGet: {
        query: (customerId, fragment) => {
            const parameters = {
                customer_id: customerId
            };

            return createQuery("customerGet",fragment, parameters);
        },
        responseField: "customerGet"
    },
    customerUpdateExemptionEndpointParams: {
        query: (customerId, status, customerTier) => {
            const parameters = {
                customer_id: customerId,
                status: status,
                tier: customerTier
            };

            return createMutation("customerUpdateExemption", parameters);
        },
        responseField: "customerUpdateExemption"
    },

    customerWipeEndpointParams: {
        query: (customerId) => {
            const parameters = {
                customer_id: customerId
            };

            return createMutation("customerWipe", parameters);
        },
        responseField: "customerWipe"
    },

    customerUpdateStandardInformation: {
        query: (customerId, param) => {
            const parameters = {
                customer_id: customerId,
                birthdate: param.birthdate,
                gender: param.gender,
                height: param.height,
                organization: param.organization,
                weight: param.weight,
                first_name: param.name,
                last_name: param.family_name,
            };

            return createMutation("customerUpdateStandardInformation", parameters);
        },
        responseField: "customerUpdateStandardInformation"
    }
}

const trainerEndpoints = {

    trainerCreateEndpointParams: {
        query: (firstName, lastName, email, password, tier) => {
            const parameters = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                tier: tier
            };

            return createMutation("trainerCreate", parameters);
        },
        responseField: "trainerCreate"
    },

    trainerFieldPrecheckEndpointParams: {
        query: (field, context, value) => {
            const parameters = {
                field: field,
                context: context,
                value: value
            };

            return createQuery("trainerFieldPrecheck", globals.SIMPLE_CONFIRMATION_RESPONSE_FRAGMENT, parameters);
        },
        responseField: "trainerFieldPrecheck"
    },

    trainerGetAllEndpointParams: {
        query: (fragment) => {
            return createQuery("trainerGetAll", fragment);
        },
        responseField: "trainerGetAll"
    },

    trainerGetSecureEndpointParams: {
        query: (trainerId, fragment) => {
            const parameters = {
                trainer_id: trainerId
            };

            return createQuery("trainerGetSecure", fragment, parameters);
        },
        responseField: "trainerGetSecure"
    },

    trainerUpdateTierEndpointParams: {
        query: (trainerId, tier) => {
            const parameters = {
                trainer_id: trainerId,
                tier: tier
            };

            return createMutation("trainerUpdateTier", parameters);
        },
        responseField: "trainerUpdateTier"
    },

    trainerWipeEndpointParams: {
        query: (trainerId) => {
            const parameters = {
                trainer_id: trainerId
            };

            return createMutation("trainerWipe", parameters);
        },
        responseField: "trainerWipe"
    }

}

export {
    adminEndpoints,
    customerEndpoints,
    trainerEndpoints
};
