import React from "react";
import { Link } from "react-router-dom";
import * as endpoints from "../../endpoints";
import * as globals from "../../globals";
import * as utils from "../../utils";
import "../css/Page.css";
import { getPlatformName } from "../../client-config";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            success: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const loginObj = this;

        const loginDetailFragment = `
            ... on ${globals.API_RESPONSE_TYPES.LOGIN_RESPONSE} {
                success,
                ${globals.LOGIN_TOKENS.REFRESH_TOKEN}
            }
        `;

        const email = this.state.email;
        const password = this.state.password;

        const queryFields = [email, password, loginDetailFragment];

        utils.issueRequest(endpoints.adminEndpoints.adminLoginEndpointParams, queryFields)
            .then(loginResponse => {
                if (!loginResponse.success) {
                    loginObj.setState({ success: false });
                    return;
                }

                loginObj.setState({ success: true });

                localStorage.setItem("adminRefreshToken", loginResponse[globals.LOGIN_TOKENS.REFRESH_TOKEN]);

                sessionStorage.setItem("isLoggedIn", true);

                loginObj.props.history.push("/");
            });
    }

    render() {
        return (
            <div className="login-page-wrapper">
                <div className="pb-2">
                    <div className="auth-page-header">{getPlatformName()} Admin Portal</div>
                </div>
                <form onSubmit={this.handleSubmit} className="auth-form">
                    <div className="modal-body row">
                        <label htmlFor="email" className="text-light col-md-3">Email</label>
                        <div className="col-md-9">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="modal-body row">
                        <label htmlFor="password" className="text-light col-md-3">Password</label>
                        <div className="col-md-9">
                            <input type="password" className="form-control" id="password" placeholder="Password" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="modal-body row justify-content-center text-light"><Link to="/reset-password" className="text-light pl-1"><u>I forgot my password</u></Link></div>
                    <div>
                        <button type="submit" className="main-button auth-button">Login</button>
                        {
                            !this.state.success && (
                                <p className="text-light">Invalid Email and Password Combination</p>
                            )
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default LoginPage;
