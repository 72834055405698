const dogPoundUrls = [
  "dogpound.local.com",
  "dogpound.admin.stage.presence.fit",
  "admins.thedogpound.com"
];

const pf3Urls = [
  "pf3.local.com",
  "pf3.admin.stage.presence.fit",
  "admin.pf3.fit"
];

export function isDodPoundUrl() {
  const hostName = window.location.hostname;
  return dogPoundUrls.includes(hostName);
}

export function isPF3Url() {
  const hostName = window.location.hostname;
  return pf3Urls.includes(hostName);
}

export function getClientConfig(config) {
  if (isDodPoundUrl()) {
    if (config === "secret") {
      return DOG_POUND_SECRET_KEY;
    } else if (config === "platform_name") {
      return DOG_POUND_PLATFORM_NAME;
    } else if (config === "favicon") {
      return DOG_POUND_FAVICON;
    }
  } else if (isPF3Url()) {
    if (config === "secret") {
      return PF3_SECRET_KEY;
    } else if (config === "platform_name") {
      return PF3_PLATFORM_NAME;
    } else if (config === "favicon") {
      return PF3_FAVICON;
    }
  } else {
    if (config === "secret") {
      return FITNESS_SECRET_KEY;
    } else if (config === "platform_name") {
      return PRESENCE_PLATFORM_NAME;
    } else if (config === "favicon") {
      return PRESENCE_FAVICON;
    }
  }
}

const FITNESS_SECRET_KEY = "sk_ft_PoW3r37g9OUWA7S39KKE";
const DOG_POUND_SECRET_KEY = "sk_dp_PoW3r37g9OUWA7S39MMJ";
const PF3_SECRET_KEY = "sk_pf3_PoW3r37g9OUWA7S39OOZ";

const DOG_POUND_PLATFORM_NAME = "DOGPOUND";
const PRESENCE_PLATFORM_NAME = "Presence";
const PF3_PLATFORM_NAME = "PF3";

const DOG_POUND_FAVICON = "/favicon-dp.ico";
const PRESENCE_FAVICON = "/logo.ico";
const PF3_FAVICON = "/favicon.ico";

export function getSecretKey() {
  return getClientConfig("secret");
}

export function getPlatformName() {
  return getClientConfig("platform_name");
}

export function getPlatformFavIcon() {
  return getClientConfig("favicon");
}
