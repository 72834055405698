import React from "react";
import { BulkDeleteButton, Datagrid, EditButton, EmailField, List, TextField } from "react-admin";
import * as globals from "../../globals";
import SearchFilterWidget from "../widgets/SearchFilterWidget";
import { isDodPoundUrl } from "../../client-config";

class CustomerListEntry extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <List {...this.props} bulkActionButtons={<BulkDeleteButton {...this.props} undoable={false} />} filters={<SearchFilterWidget />}>
                <Datagrid>
                    <TextField label="ID" source={globals.USER_ATTRIBUTES.CUSTOMER.CUSTOMER_ID} />
                    <EmailField label="Email" source={globals.USER_ATTRIBUTES.CUSTOMER.EMAIL} />
                    <TextField label="Exempt" source={globals.USER_ATTRIBUTES.CUSTOMER.EXEMPT} />
                    {!isDodPoundUrl() && (
                        <TextField label="Tier" source={globals.USER_ATTRIBUTES.CUSTOMER.TIER} />
                    )}
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default CustomerListEntry;
