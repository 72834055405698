import React from "react";
import { Route, withRouter } from "react-router-dom";
import * as endpoints from "../endpoints";
import * as globals from "../globals";
import * as utils from "../utils";
import LoginPage from "./pages/LoginPage";
import { Helmet } from "react-helmet";
import MainDashboardPage from "./pages/MainDashboardPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import "./css/App.css";
import { getPlatformFavIcon, getPlatformName } from "../client-config";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.loginWithRefreshToken = this.loginWithRefreshToken.bind(this);
    }

    componentDidMount() {
        this.loginWithRefreshToken();
    }

    loginWithRefreshToken() {
        const loggedIn = sessionStorage.getItem("isLoggedIn");

        if (!loggedIn) {
            const appObj = this;
            const refreshToken = localStorage.getItem("adminRefreshToken");

            const loginDetailFragment = `
                ... on ${globals.API_RESPONSE_TYPES.LOGIN_RESPONSE} {
                    success
                }
            `;

            const queryFields = [refreshToken, loginDetailFragment];

            try {
                if (!refreshToken) {
                    throw "Existing tokens not found";
                }

                utils.issueRequest(endpoints.adminEndpoints.adminLoginRefreshTokenEndpointParams, queryFields)
                    .then(loginResponseInfo => {
                        if (!loginResponseInfo.success === "true") {
                            throw "Token has expired";
                        }

                        sessionStorage.setItem("isLoggedIn", true);

                        appObj.props.history.push("/");
                    })
                    .catch(err => {
                        console.error(err);
                        throw "Failed to login admin with unknown error";
                    });
            }
            catch (err) {
                console.log(err);
                localStorage.clear();
                sessionStorage.clear();
            }
        }
    }

    render() {
        return (
            <div className="App">
                <Helmet>
                    <link rel="icon" href={getPlatformFavIcon()} />
                    <title>{getPlatformName()} Admin Portal</title>
                </Helmet>
                <Route exact path="/" render={(props) => <MainDashboardPage {...props} />} />
                <Route exact path="/login" render={(props) => <LoginPage {...props} />} />
                <Route exact path="/reset-password" render={(props) => <PasswordResetPage {...props} />} />
            </div>
        );
    }
}

export default withRouter(App);
